export const COLORS = {
  white: '#fff',
  black: '#000',
  brand: 'rgba(184, 0, 249, 1)',
  accent_1: 'rgba(236, 191, 44, 1)',
  bg_page: 'rgba(255, 255, 255, 1)',
  bg_darker: 'rgba(247, 243, 250, 1)',
  bg_default: 'rgba(255, 255, 255, 1)',
  bg_hover: '#F9F8F9;',
  border: 'rgba(234, 231, 243, 1)',
  border_lighter:'#EAE7F3',
  neutral_4: 'rgba(4, 0, 20, 0.98)',
  neutral_3: 'rgba(4, 0, 20, 0.69)',
  neutral_2: 'rgba(4, 0, 20, 0.30)',
  neutral_1: 'rgba(4, 0, 20, 0.15)',
  featured_card_title: 'rgba(4, 0, 20, 1)',
  featured_card_body: 'rgba(4, 0, 20, 1)',
  featured_card_tag: 'rgba(4, 0, 20, 0.69)',
  featured_card_name: 'rgba(4, 0, 20, 0.30)',
  featured_card_divider: 'rgba(4, 0, 20, 0.10)',
  featured_card_bg: 'rgba(247, 243, 250, 1)',
  image_border: 'rgba(0, 0, 0, 0.10)',
  image_default: 'rgba(196, 196, 209, 1)',
  invert_4: 'rgba(255, 255, 255, 1)',
  invert_3: 'rgba(255, 255, 255, 0.69)',
  invert_2: 'rgba(255, 255, 255, 0.40)',
  invert_1: 'rgba(255, 255, 255, 0.18)',
  newsletter_bg: 'rgba(243, 241, 250, 1)',
  input_bg: 'rgba(255, 255, 255, 0.20)',
  tag_bg: 'rgba(242, 237, 243, 0.6)',
  tag_border: '#F2EDF3;',
}